import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 0, 77',
		'primary-dark': '0, 0, 47',
		'accent': '225, 48, 90',
		'accent-plus': '255, 255, 255',
	},
});
